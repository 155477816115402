<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-4">
        <div class="breadcrumb">
          <h1>Modificar Venta
            <span class="h4 pl-2">
              N°: {{getCorrelativo}}
            </span>
          </h1>
        </div>
      </div>
      <transition name="fade">
        <div v-if="showCortinaInformativa"
         class="col-sm-8 pl-0 navbar-reubicado">
          <div class="row mx-0">
            <div
              v-if="parseFloat(getPacienteToShow.tratamiento) !== 0
              && getPacienteToShow.tratamiento !== null
              && parseFloat(getPacienteToShow.anticipo) !== 0
              && getPacienteToShow.anticipo !== null"
              class="col-sm-3 pt-2 pb-1 bg-yellow-custom
              text-center border-right border-white">
              Total Tratamiento: <br>
              {{getPacienteToShow.tratamiento | numeral('0,0.00')}}
            </div>
            <div v-else class="col-sm-3"></div>
            <div
              v-if="parseFloat(getPacienteToShow.anticipo) !== 0
              && getPacienteToShow.anticipo !== null"
              class="col-sm-2 pt-2 pb-1 bg-yellow-custom text-center
              border-left border-right border-white"
            >
              Tiene Anticipo: <br>
              {{getPacienteToShow.anticipo | numeral('0,0.00')}}
            </div>
            <div v-else class="col-sm-2 text-right mt-2">
              <b>H.C :</b> {{getPacienteToShow.numero}}
            </div>
            <div class="col-sm-2 py-2 bg-sky-light text-white border-left border-white">
              <b>Edad:</b> {{getPacienteToShow.edad}} años
            </div>
            <div
              v-if="getPacienteToShow.tipo_seguro==='Conjunto'"
              class="col-sm-3 px-1 py-2 bg-sky-light text-white"
            >
              <b>Seguro:</b> {{getPacienteToShow.cobertura}}
              <span v-if="getPacienteToShow.tipo_familiar">
                ({{getPacienteToShow.tipo_familiar}})
              </span>
            </div>
            <div
              v-if="getPacienteToShow.numero_poliza || getPacienteToShow.cod_asegurado"
              class="col-sm-2 px-1 py-2 bg-sky-light text-white"
            >
              <template v-if="getPacienteToShow.numero_poliza">
                <b>N° Poliza: </b>{{getPacienteToShow.numero_poliza}}
              </template>
              <template v-else>
                <b>N° Cod.:</b>{{getPacienteToShow.cod_asegurado}}
              </template>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header" :class="checkFacturaTercero ? 'bg-skyblue':''">
            <div class="col-sm-5">
              <button type="submit"
                @click="guardarFormulario"
                class="btn bg-custom-green mr-2 rounded"
                :disabled="buttonDisabled"
              >
                Guardar
              </button>
              <router-link :to="{ name: 'facturacion-index' }">
                <button class="btn btn-white border border-light rounded">
                  Regresar
                </button>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <!-- contiene formulario para factura -->
            <FormFactura />
            <!-- Tabla de los Servicios -->
            <SectionTableServices />
            <!-- detalles finales de factura -->
            <DetalleFactura />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import moment from 'moment';
import FormFactura from '../components/TheFormFactura.vue';
import SectionTableServices from '../components/SectionTableServices.vue';
import DetalleFactura from '../components/TheDetalleFactura.vue';
import { util } from '../../../plugins/util';

export default {
  name: 'edit',
  components: {
    FormFactura,
    SectionTableServices,
    DetalleFactura,
  },
  async created() {
    this.bloquearElementosInterfaz();
    await this.callEditApi(this.id);
    this.asignarfechaCreditoVencimiento();
    this.cargarInsigniasFormaPagoEdit();
    this.isLoading = false;
  },
  beforeDestroy() {
    this.resetState();
  },
  data() {
    return {
      isLoading: true,
      buttonDisabled: false,
      id: atob(this.$route.params.id),
    };
  },
  methods:
  {
    bloquearElementosInterfaz() {
      this.setModoEdicion(true);
      this.setShowDatoFactura(false);
      this.setDisabledStatusFactura(true);
      this.setDisabledDatePicker(true);
      this.setStatusComboMoneda(true);
    },
    redirect(path) {
      this.$router.push(path);
    },
    async guardarFormulario() {
      if (this.getPacienteSelected === null) {
        util.showNotify('Elija un Paciente', 'warn');
        return;
      }
      if (this.isEnabledFormaPago === false) {
        util.showNotify('Ingrese forma de pago!', 'warn');
        return;
      }
      if (this.checkSeguroEstado && parseFloat(this.totalSeguro) <= 0) {
        util.showNotify('Ingrese un servicio con seguro!', 'warn');
        return;
      }
      if (this.caja + this.banco + this.tarjeta + this.totalMontoAjuste > this.getTotal) {
        util.showNotify('el monto de pago es superior al total', 'warn');
        return;
      }
      this.buttonDisabled = true;
      const TURNOS_DETALLES = this.dataDetalleTurno();

      let isValidateTurno = true;
      TURNOS_DETALLES.forEach((el, index) => {
        this.getListaTabla.forEach((el2, index2) => {
          if (index !== index2 && el2.medicoTempSelected && !this.reservaId) {
            if (el.hora_turno === el2.hora_turno
              && el.fecha_turno === el2.fecha_turno
              && el.turno_medico_id === el2.turno_medico_id
            ) {
              util.showNotify(`El turno de la fila ${index2 + 1} no debe repetirse`, 'warn');
              isValidateTurno = false;
            }
          }
        });
      });
      if (isValidateTurno === false) {
        this.buttonDisabled = false;
        return;
      }
      const UPDATE_OK = await this.callUpdateApi(this.id);
      if (UPDATE_OK) {
        this.redirect({ name: 'facturacion-index' });
      }
      this.buttonDisabled = false;
    },
    resetState() {
      this.resetFacturacion();
      this.resetFormFactura();
      this.resetTableAndAddService();
      this.resetAddServiceModal();
      this.resetFormaPagoModal();
      this.resetFormaPagoFacturaTercero();
      this.resetDetalleFactura();
      this.resetModalReserva();
      this.resetModalFiltrarContizacion();
    },
    asignarfechaCreditoVencimiento() {
      const year = this.getDatePicker.getFullYear();
      const month = this.getDatePicker.getMonth();
      const day = this.getDatePicker.getDate();
      const NEW_DAY = parseInt(day, 10) + parseInt(this.getInputDiasCredito, 10);
      const FechaNueva = new Date(year, month, NEW_DAY);
      this.setFechaCreditoVencimiento(moment(FechaNueva).format('DD/M/YYYY'));
    },
    ...mapMutations('facturacion', [
      'setShowCortinaInformativa',
      'setModoEdicion',
    ]),
    ...mapMutations('formFactura', [
      'setDisabledStatusFactura',
      'setShowDatoFactura',
      'setDisabledDatePicker',
      'setStatusComboMoneda',
    ]),
    ...mapMutations('formaPagoModal', [
      'setFechaCreditoVencimiento',
    ]),
    ...mapActions('facturacion', [
      'callEditApi',
      'callUpdateApi',
      'resetFacturacion',
      'callValidateTurno',
    ]),
    ...mapActions('formFactura', [
      'resetFormFactura',
    ]),
    ...mapActions('tableAndAddService', [
      'resetTableAndAddService',
    ]),
    ...mapActions('addServiceModal', [
      'resetAddServiceModal',
    ]),
    ...mapActions('formaPagoModal', [
      'resetFormaPagoModal',
    ]),
    ...mapActions('formaPagoFacturaTercero', [
      'resetFormaPagoFacturaTercero',
    ]),
    ...mapActions('detalleFactura', [
      'cargarInsigniasFormaPagoEdit',
      'resetDetalleFactura',
    ]),
    ...mapActions('modalReserva', [
      'resetModalReserva',
    ]),
    ...mapActions('modalFiltrarCotizacion', [
      'resetModalFiltrarContizacion',
    ]),
    dataDetalleTurno() {
      const turnos = [];
      this.getListaTabla.forEach((element) => {
        const dto = {
          hora_turno: element.hora_turno,
          fecha_turno: element.fecha_turno,
          turno_medico_id: element.turno_medico_id ? element.turno_medico_id : '',
          numero_ticket: element.numero_ticket ? element.numero_ticket : '',
        };
        turnos.push(dto);
      });
      return turnos;
    },
  },
  computed:
  {
    ...mapFields('formFactura', [
      'checkSeguroEstado',
      'checkFacturaTercero',
    ]),
    ...mapFields('formaPagoFacturaTercero', [
      'caja',
      'banco',
      'tarjeta',
    ]),
    ...mapFields('detalleFactura', [
      'totalSeguro',
    ]),
    showCortinaInformativa: {
      set(value) {
        this.setShowCortinaInformativa(value);
      },
      get() {
        return this.getShowCortinaInformativa;
      },
    },
    ...mapGetters('facturacion', [
      'getShowCortinaInformativa',
      'getPacienteToShow',
      'getCorrelativo',
    ]),
    ...mapGetters('formFactura', [
      'getDatePicker',
      'getPacienteSelected',
    ]),
    ...mapGetters('formaPagoModal', [
      'getInputDiasCredito',
      'isEnabledFormaPago',
      'totalMontoAjuste',
    ]),
    ...mapGetters('detalleFactura', [
      'getSubTotal',
      'getTotal',
    ]),
    ...mapFields('formaPagoModal', [
      'radioFormaPagoselected',
    ]),
    ...mapGetters('tableAndAddService', [
      'getListaTabla',
    ]),
  },
};
</script>

<style scoped>
.bg-skyblue
{
  background: #61c6f2 !important;
}
.btn-white{
  background: white;
  color: black;
}
.bg-yellow-custom {
  background-color: #f9d772;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(0px, -32px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.label-facturacion-tercero{
  line-height: 1.2;
  font-size: 11px;
}
</style>
